import one from '../assets/svg/projects/one.png'
import two from '../assets/svg/projects/two.png'
import three from '../assets/svg/projects/three.png'
import four from '../assets/svg/projects/four.png'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'



export const projectsData = [
    {
        id: 1,
        projectName: 'HBKWORLD',
        projectDesc: 'HBK provides comprehensive solutions across the product life cycle uniting the physical world of sensors ...',
        tags: ['AEM', 'Tailwind', 'Dispatcher', "Typesense","Scrapy"],
        code: '',
        demo: 'https://www.hbkworld.com',
        image: one
    },
    {
        id: 2,
        projectName: 'Galapagos Health',
        projectDesc: 'Galapagos is a fully integrated biotechnology company focused on discovering, developing, and commercializing innovative medicines',
        tags: ['AEM', 'jQuery','Gulp','Storybook'],
        code: '',
        demo: 'https://www.galapagoshealth.com/',
        image: two
    },
    {
        id: 3,
        projectName: 'Abbvie Pro',
        projectDesc: 'AbbVie Inc. is an American pharmaceutical company headquartered in North Chicago, Illinois. It is ranked 6th on the list ',
        tags: ['Django', 'CSS', 'Material Ui'],
        code: '',
        demo: 'https://www.abbviepro.com/',
        image: three
    },
    {
        id: 4,
        projectName: 'CutOutWiz',
        projectDesc: 'CutOutWiz is an AI driven company specializing in Image, Video, and 3D/CGI post-production at scale.',
        tags: ['.NET', 'POSTGRESQL','Shopify','jQuery'],
        code: '',
        demo: 'https://apps.shopify.com/cutoutwiz',
        image: four
    },
    {
        id: 5,
        projectName: 'WinIt',
        projectDesc: 'WinIt is an android application where user can play live game and earn real money',
        tags: ['Android', 'Dynamodb','Serverless','Python','Api-Gateway'],
        code: '',
        demo: '',
        image: five
    },
    {
        id: 6,
        projectName: 'Dropslab',
        projectDesc: 'The live chat functionality integrated into the Workflow Management Systems connects the teams on field seamlessly with the teams in office. Interact with your ...',
        tags: ['.NET','Angular','MSSQL'],
        code: '',
        demo: 'https://dropslab.com/',
        image: six
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/