export const achievementData = {
    bio : "Putting quantified achievements on a resume is great, but I just don’t work with hard numbers. I have no data to support the quality of my work. If you have lots of relevant experience, the heading statement of your resume will be a summary.",
    achievements : [
        {
            id : 1,
            title : 'Adobe Certified Expart',
            details : 'The Adobe Digital Experience Credential Program was created to help you prove your skills in Adobe Experience Cloud products. Becoming Adobe Certified allows you to showcase your expertise and impress clients and employers alike.',
            date : 'FEB 28, 2022',
            field : 'Development',
            image : 'https://images.credly.com/size/340x340/images/176ea367-7201-4605-a51a-c423bc6a518e/Adobe_Certified_Expert_Experience_Cloud_products_Digital_Badge.png'
        },
        {
            id : 2,
            title : 'Certification for Management Professionals',
            details : 'The Advanced Certification for Management Professionals working for organizations. The participants should at least have a bechalor degree .',
            date : 'SEP 20, 2023',
            field : 'Management',
            image : 'https://iba-du.edu//upload_images/header_top/du-logo.png'
        },
        {
            id : 3,
            title : 'Adobe Community Contributor',
            details : 'Whether you are looking for a specific answer, documentation and support resources, or opportunities to connect with peers — you have come to the right place.',
            date : 'NOV 10, 2021',
            field : 'Adobe Community',
            image : 'https://experienceleague.adobe.com/assets/img/adobe-red-logo.svg'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/
