export const experienceData = [
    {
        id: 1,
        company: 'Brain Station 23 Ltd',
        jobtitle: 'Techinal Lead',
        startYear: '2022',
        endYear: 'Current'
    },
    {
        id: 2,
        company: 'Brain Station 23 Ltd',
        jobtitle: 'Senior Software Engineer',
        startYear: '2020',
        endYear: '2021'
    },
    {
        id: 3,
        company: 'Brain Station 23 Ltd',
        jobtitle: 'Software Engineer',
        startYear: '2018',
        endYear: '2019'
    },
    {
        id: 4,
        company: 'Brain Station 23 Ltd',
        jobtitle: 'Junior Software Engineer',
        startYear: '2017',
        endYear: '2018'
    },
]
