export const educationData = [
    
    {
        id: 1,
        institution: 'Stamford University Bangladesh',
        course: 'BSc in Computer Science and Engineering',
        startYear: '2013',
        endYear: '2018'
    },
    {
        id: 2,
        institution: 'Institute of Bussiness Administration, University of Dhaka',
        course: 'Advanced Certification for Management Professionals',
        startYear: '2023',
        endYear: '2023'
    }
]