export const blogData = [
    {
        id: 1,
        title: 'Discover the art of alleviating server stress by seamlessly migrating assets from AEM DAM to third-party sources.',
        description: 'If your digital platform experiences high levels of traffic or if multiple applications share common assets ...',
        date: 'Aug 21, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*00n4cKYQQ2sqsbmGwWTebw.png',
        url: 'https://kaikubad.medium.com/discover-the-art-of-alleviating-server-stress-by-seamlessly-migrating-assets-from-aem-dam-to-4956ae81ab33'
    },
    {
        id: 2,
        title: 'Empower AEM performance by purging Dispatcher cache effortlessly from backend (servlets or services)!',
        description: 'Our main goal is to clear dispatcher cache from aem backend (i.e: Servlet, Model, Services, Process)',
        date: 'Aug 28, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*RevxqdNT2pQsODHq7f0HRA.png',
        url: 'https://kaikubad.medium.com/empower-aem-performance-by-purging-dispatcher-cache-effortlessly-from-backend-servlets-or-72d56e93579d'
    },
    {
        id: 3,
        title: 'Unleashing the Power of Sling Dynamic Includes: A Deep Dive into Dynamic Content Rendering in AEM',
        description: 'While working for a client recently, requested to find solution on content cache and experience fragment cache. We have ...',
        date: 'Aug 14, 2020',
        image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*WzR45lvmgX0WGGpxRKrW4A.png',
        url: 'https://kaikubad.medium.com/unleashing-the-power-of-sling-dynamic-includes-a-deep-dive-into-dynamic-content-rendering-in-aem-9d8f62481656'
    },
    {
        id: 4,
        title: 'AEM’s replication system, along with Replication Agents and Dispatcher Flush Agents',
        description: 'The process starts when author selects quick publish/publish page after editing. Then a servlet is called /libs/wcm/core/content/reference.json?',
        date: 'JAN 25, 2024',
        image: 'https://miro.medium.com/v2/resize:fit:720/format:webp/1*DZx1NmtXOxToh-UUOKDDOQ.jpeg',
        url: 'https://kaikubad.medium.com/aems-replication-system-along-with-replication-agents-and-dispatcher-flush-agents-b0a7a909ce74'
    },
    {
        id: 5,
        title: 'GET MICHAEL\'S OR TREVOR\'S VEHICLES FULLY UPGRADED IN GTA V FOR FREE',
        description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
        date: 'Oct 1, 2020',
        image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
        url: 'https://kaikubad.medium.com/unleashing-the-power-of-sling-dynamic-includes-a-deep-dive-into-dynamic-content-rendering-in-aem-9d8f62481656'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/
