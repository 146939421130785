export const socialsData = {
    github: '',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/johnykbd',
    instagram: '',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: 'https://kaikubad.medium.com/',
    stackOverflow: '',
    gitlab: '',
    youtube: '',
    whatsapp:'https://wa.me/+8801814151802'
}